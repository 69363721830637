import React, { useEffect, useState } from "react";
import axios from "axios";
import { PuffLoader } from "react-spinners";
import { MdDelete } from "react-icons/md";

const API = "https://xinvestor-co.onrender.com";

function JobsList() {
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(10);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${API}/jobs`);
        setJobs(response.data.jobs);
      } catch (error) {
        setError(
          error.message || "An error occurred while fetching startup names."
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobs();
  }, []);

  // Pagination Logic
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <PuffLoader color="#1a202c" loading size={99} speedMultiplier={2} />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  return (
    <div className="w-full flex flex-col items-center my-8 h-full relative overflow-x-auto">
      <h1 className="flex items-center justify-center text-2xl md:text-4xl font-semibold m-6">
        Venture Capital Jobs
      </h1>

      <table className="w-3/4 text-sm text-left rtl:text-right text-gray-900 dark:text-gray-400">
        <thead className="cursor-pointer text-l text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="px-6 py-3">
              VC Firm
            </th>
            <th scope="col" class="px-6 py-3">
              Position
            </th>
            <th scope="col" class="px-6 py-3">
              Location
            </th>
            <th scope="col" class="px-6 py-3">
              Date
            </th>
            <th scope="col" class="px-6 py-3">
              Link
            </th>
          </tr>
        </thead>
        <tbody>
          {currentJobs.map((job) => (
            <tr key={job._id}
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            >
              <th
              scope="row"
              class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >{job.firmName}</th>
              <td class="px-6 py-4">{job.role}</td>
              <td class="px-6 py-4">{job.location}</td>
              <td class="px-6 py-4">{new Date(job.uploadDate).toLocaleDateString("en-GB")}</td>
              <td>
                <a
                  href={job.link}
                  target="_blank"
                  className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                  rel="noopener noreferrer"
                >
                  Apply
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex justify-center my-4">
        <button
          onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
          disabled={currentPage === 1}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-2 rounded"
        >
          Previous
        </button>
        {Array.from({ length: Math.min(10, Math.ceil(jobs.length / jobsPerPage)) }).map(
          (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`${
                currentPage === index + 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-300 text-gray-700"
              } hover:bg-blue-700 hover:text-white font-bold py-2 px-4 rounded`}
            >
              {index + 1}
            </button>
          )
        )}
        <button
          onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
          disabled={currentPage === Math.ceil(jobs.length / jobsPerPage)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-2 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default JobsList;
