import React from "react";

const KeyValue = ({ label, value }) => (
  <div style={{ margin: "10px 0px 10px 0px" }}>
    <strong>{label}:</strong>{" "}
    {typeof value === "object" ? <NestedObject obj={value} /> : value}
  </div>
);

const NestedObject = ({ obj }) => (
  <div style={{ marginLeft: "20px" }}>
    {Object.entries(obj).map(([key, value]) => (
      <KeyValue key={key} label={key} value={value} />
    ))}
  </div>
);

const FullNote = ({ data }) => {
  return (
    <div className="max-w-screen-md mx-auto p-8 bg-white rounded-lg shadow-md mt-8">
      {Object.entries(data).map(([key, value]) => (
        <KeyValue key={key} label={key} value={value} />
      ))}
    </div>
  );
};

export default FullNote;
