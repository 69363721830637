
const Main = () => {
	const handleLogout = () => {
		localStorage.removeItem("token");
		window.location.href = "/";
	};

	return (
		<div className="flex h-screen flex-col justify-center items-center">
			<h1 className="text-3xl text-bold mb-24">After Login Page</h1>
				<button className="border-none outline-none py-3 px-0 text-white m-3 bg-[#1DA1F2] rounded-3xl w-44 font-bold text-base cursor-pointer uppercase" onClick={handleLogout}>
					Logout
				</button>
		</div>
	);
};

export default Main;
