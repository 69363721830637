import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import FullNote from "./details";
import { Hourglass } from "react-loader-spinner";


// const API_URL = process.env.REACT_APP_URL;

const API = "https://xinvestor-co.onrender.com";

// const API = "http://localhost:8080";

function FileDetails() {
    const { id } = useParams();
    const [startupDetails, setStartupDetails] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchStartupDetails = async () => {
            try {
                const response = await axios.get(
                    `${API}/startups/id/${id}`
                );
                
                
                
                if (response !== ""){
                    // console.log(response.data.responseContents);
                    const preData = response.data.responseContents;
                    // console.log(preData.replace(/^```|```$/g, ''));
                    const withoutBackticks = preData.replace(/^```json([\s\S]*?)```$/g, '$1');
                    const parsedData = JSON.parse(withoutBackticks.replace(/,\s*([\]}])/g, '$1'));
                    setStartupDetails({ ...parsedData });
                    setLoading(false);
                } else{
                    setLoading(true);
                }
                
            } catch (error) {
                setError(
                    error.message ||
                        "An error occurred while fetching startup details."
                );
            }
        };

        fetchStartupDetails();
    }, [id]);

    if (error) {
        return <div className="text-red-500">Error: {error}</div>;
    }

    return (
        <div className="max-w-screen-md mx-auto p-8 bg-white rounded-lg shadow-md mt-8">
            <h2 className="text-3xl font-bold mb-4">Investment Note - {startupDetails.CompanyName}</h2>
            {loading ? (
                    <div className="text-center">
                    <Hourglass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={["#306cce", "#72a1ed"]}
                    />
                </div>
                ) : (
                    
                    <FullNote data={startupDetails} />
            )
        }
            
        </div>
    );
}

export default FileDetails;
