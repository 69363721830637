import React from 'react';


const Pricing = () => {
  return (
    <>
    
      <section className="w-full py-12 md:py-24 lg:py-32">
      <div className="container grid items-center gap-6 px-4 md:px-6">
        <div className="space-y-2 text-center">
          <div className="inline-block rounded-lg bg-gray-100 px-3 py-1 text-sm dark:bg-gray-800">Simple Pricing</div>
          <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl">Choose the perfect plan</h1>
          <p className="max-w-[600px] mx-auto text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
            Get started with our AI investment memo generator. All plans include unlimited users, secure access to the
            platform, and customer support.
          </p>
        </div>
        <div className="grid max-w-sm gap-4 mx-auto lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-8">
          <div className="flex flex-col gap-1.5">
            <div className="rounded-lg border border-gray-200 border-gray-200 bg-white shadow-sm overflow-hidden dark:border-gray-800 dark:border-gray-800 dark:bg-gray-950">
              <div className="p-4 border-b border-gray-200 dark:border-gray-800">
                <div className="text-2xl font-semibold">Free</div>
                <div className="text-sm text-gray-500 dark:text-gray-400">$0/mo</div>
              </div>
              <div className="grid gap-2 p-4 text-sm">
                <div className="flex items-center gap-2">
                  <CheckIcon className="h-4 w-4" />
                  <span className="font-medium">5 investment memo per month</span>
                </div>
              </div>
            </div>
            <div className="mt-auto">
              <a
                className="block w-full rounded-md border border-gray-200 border-gray-200 bg-white py-2 px-2 text-sm font-semibold shadow-sm transition-colors hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 dark:border-gray-800 dark:border-gray-800 dark:bg-gray-950 dark:hover:bg-gray-800 dark:hover:text-gray-50 dark:focus-visible:ring-gray-300"
                href="/form"
              >
                Select Free Plan
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-1.5">
            <div className="rounded-lg border border-gray-200 border-gray-200 bg-white shadow-sm overflow-hidden dark:border-gray-800 dark:border-gray-800 dark:bg-gray-950">
              <div className="p-4 border-b border-gray-200 dark:border-gray-800">
                <div className="text-2xl font-semibold">Premium</div>
                <div className="text-sm text-gray-500 dark:text-gray-400">$199/user/month</div>
              </div>
              <div className="grid gap-2 p-4 text-sm">
                <div className="flex items-center gap-2">
                  <CheckIcon className="h-4 w-4" />
                  <span className="font-medium">Unlimited investment memos per month</span>
                </div>
                <div className="flex items-center gap-2">
                  <CheckIcon className="h-4 w-4" />
                  <span className="font-medium">Download the note</span>
                </div>
                <div className="flex items-center gap-2">
                  <CheckIcon className="h-4 w-4" />
                  <span className="font-medium">Get access to every feature</span>
                </div>
                <div className="flex items-center gap-2">
                  <CheckIcon className="h-4 w-4" />
                  <span className="font-medium">Edit Investment Notes</span>
                </div>
              </div>
            </div>
            <div className="mt-auto">
              <a
                className="block w-full rounded-md bg-gray-900 py-2 px-2 text-sm font-semibold text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
                href="#"
              >
                Coming Soon!
              </a>
            </div>
          </div>
        </div>
      </div>
    </section> 
    </>
  )
}

export default Pricing

function CheckIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="20 6 9 17 4 12" />
      </svg>
    )
  }
  