import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";

// const API_URL = process.env.REACT_APP_URL;
// const API = "http://localhost:8080";
const API = "https://xinvestor-co.onrender.com";

const Login = () => {
    const navigate = useNavigate();

    const [data, setData] = useState({ email: "", password: "" });
    const [error, setError] = useState("");
    const [token, setToken] = useState(null);

    useEffect(() => {
        if (token) {
            // Redirect to the home page if token is available
            console.log("Redirecting to the home page");
            navigate("/form", { replace: true });
            window.location.reload();
        }
    }, [token, navigate]);

    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            
            const url = `${API}/api/auth`;
            const {
                data: { data: generatedToken },
            } = await axios.post(url, data);
            localStorage.setItem("token", generatedToken);
            console.log("Token:", generatedToken);

            // Set the token in the state
            setToken(generatedToken);
            navigate("/form", { replace: true });
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                setError(error.response.data.message);
            }
        }
    };

    return (
        <div className="w-full min-h-screen md:mb-0 bg-[#f5f5f5] flex items-center justify-center overflow-x-hidden">
            <div className="w-full md:w-8/12 my-10 h-[5/6] md:h-[500px] md:flex rounded-xl md:shadow-2xl md:overflow-auto">

                <div className="flex md:mx-0 mx-auto md:flex-1 flex-2 flex-col items-center justify-center bg-white rounded-2xl md:rounded-l-lg w-11/12 md:h-full h-96 md:mb-0 mb-16">
                    <form
                        className="flex flex-col items-center"
                        onSubmit={handleSubmit}
                    >
                        <h1 className="text-3xl m-4">Login to Your Account</h1>
                        <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            onChange={handleChange}
                            value={data.email}
                            required
                            className="outline-none border-none w-80 p-4 rounded-xl bg-[#edf5f3] mx-0 my-3 text-base"
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            onChange={handleChange}
                            value={data.password}
                            required
                            className="outline-none border-none w-80 p-4 rounded-xl bg-[#edf5f3] mx-0 my-3 text-base"
                        />
                        {error && (
                            <div className="p-4 w-3/4 mx-0 my-2 text-base bg-red-500 text-white rounded-lg text-center">
                                {error}
                            </div>
                        )}
                        <button
                            type="submit"
                            className="border-none outline-none py-3 px-0 text-white m-3 bg-gray-900 rounded-3xl w-44 font-bold text-base cursor-pointer uppercase hover:bg-gray-700"
                        >
                            Sign In
                        </button>
                    </form>
                </div>
                <div className=" w-11/12 h-40 md:mt-0 mt-18 md:h-full flex flex-1 mx-auto md:flex-2 flex-col md:rounded-r-lg md:rounded-l-none rounded-3xl items-center justify-center bg-gray-900 ">
                    <h1 className="m-4 text-white text-4xl self-center">
                        New Here ?
                    </h1>
                    <Link to="/signup">
                        <button
                            type="button"
                            className="border-none outline-none py-3 px-0 bg-white rounded-3xl w-44 font-bold text-base cursor-pointer uppercase"
                        >
                            Sign Up
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Login;
