import { IoMdAnalytics } from "react-icons/io";
import { BsDatabaseFillUp } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const LandingPage = () => {
  const [isLoggedIn, setIssLoggedIn] = useState(false);

  useEffect(() => {
    // Check user authentication status (you might have your own logic)
    const userToken = localStorage.getItem("token");
    setIssLoggedIn(!!userToken);
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Header */}
      <header className="bg-blue-500 text-white py-16 bg-cover bg-center relative">
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="container mx-auto text-center relative z-10">
          <h1 className="text-5xl font-bold mb-4">
            Generate Investment Memos with AI in seconds
          </h1>
          <p className="text-xl mb-8">
            Unlock the Power of Google's Gemini AI-powered Memo Tool
          </p>
          {/* <a
            href="https://forms.gle/fP7gcntWpk7njE5JA"
            className="bg-yellow-500 hover:bg-yellow-400 text-blue-900 font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 transform hover:scale-105 ease-in-out"
          >
         Please fill the form to join our waiting list
          </a> */}
          {isLoggedIn ? (
            <Link
              to="/form"
              className="bg-yellow-500 hover:bg-yellow-400 text-blue-900 font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 transform hover:scale-105 ease-in-out"
            >
              Generate the Memo Now!
            </Link>
          ) : (
            <Link
              to="/signup"
              className="bg-yellow-500 hover:bg-yellow-400 text-blue-900 font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 transform hover:scale-105 ease-in-out"
            >
              Generate the Memo Now!
            </Link>
          )}
        </div>
      </header>

      {/* Section 1: Problem Statement */}
      <section className="py-12">
        <div className="container mx-auto p-4">
          <h2 className="text-3xl font-semibold text-center my-12">
            The Challenge while Generating Investment Memos
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {/* Problem 1 */}
            <div className="p-8 bg-white rounded-lg shadow-xl">
              <h3 className="text-2xl font-semibold mb-4">
                Manual Pitch Deck Evaluation
              </h3>
              <p className="text-gray-700">
                Manual pitch deck go through takes around 5 minutes. If it is
                not worth investment, it causes the loss of time.
              </p>
            </div>

            {/* Problem 2 */}
            <div className="p-8 bg-white rounded-lg shadow-xl">
              <h3 className="text-2xl font-semibold mb-4">Time Consumption</h3>
              <p className="text-gray-700">
                Investment memo generation is manual process. It almost takes
                3-4 hours of an analyst/associate. They have to visit multiple
                websites, articles to create a comprehensive investment memos.
              </p>
            </div>

            {/* Problem 3 */}
            <div className="p-8 bg-white rounded-lg shadow-xl">
              <h3 className="text-2xl font-semibold mb-4">Less Accuracy</h3>
              <p className="text-gray-700">
                Many times, an analyst/associate might not be able to find the
                relevant information about the company and sector. This leads to
                less accuracy and poor decision-making.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section 2: Our Game-Changing Solution */}
      <section className="bg-blue-500 text-white py-16">
      
        <div className="container mx-auto text-center ">
        {/* <div className="absolute inset-0 bg-black opacity-50"></div> */}
          <h2 className="text-3xl font-semibold mb-8">
            Introducing the Future of Venture Capital
          </h2>
          <p className="text-xl mb-8">Gemini AI-Powered Investment Memo Tool</p>   
          {isLoggedIn ? (
            <Link
              to="/form"
              className="bg-yellow-500 hover:bg-yellow-400 text-blue-900 font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 transform hover:scale-105 ease-in-out"
            >
              Generate the Memo Now!
            </Link>
          ) : (
            <Link
              to="/signup"
              className="bg-yellow-500 hover:bg-yellow-400 text-blue-900 font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 transform hover:scale-105 ease-in-out"
            >
              Generate the Memo Now!
            </Link>
          )}
        </div>
      </section>

      {/* Section 3: The Power of Data-Driven Decisions */}
      <section className="py-12">
        <div className="container mx-auto">
          <h2 className="text-3xl font-semibold text-center mb-8">
            Key Features
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-4">
            {/* Data Visualization */}
            <div className="p-8 bg-white  rounded-lg shadow-xl">
              <div className="rounded-full bg-blue-700 h-16 w-16 flex items-center justify-center text-white text-2xl mb-4 mx-auto">
                <IoMdAnalytics />
              </div>
              <h3 className="text-2xl font-semibold mb-4 flex items-center justify-center">
                Ultra-fast Memo Generation
              </h3>
              <p className="text-gray-700">
                Our tool does not take more than 10 seconds to generate the investment memo after submitting the pitch deck of the startup. You can generate as many memos as possible.
              </p>
            </div>

            {/* Real-time Data Integration */}
            <div className="p-8 bg-white  rounded-lg shadow-xl ">
              <div className="rounded-full bg-blue-700 h-16 w-16 flex items-center justify-center text-white text-2xl mb-4 mx-auto">
                <BsDatabaseFillUp />
              </div>
              <h3 className="text-2xl font-semibold mb-4 flex items-center justify-center">
                Real-time Data from Google's Gemini AI
              </h3>
              <p className="text-gray-700">
                Our platform uses Gemini AI to collect real-time accurate data from various sources
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-12">
        <div className="container mx-auto p-4">
          <h2 className="text-3xl font-semibold text-center mb-16">
            Why xInvestor?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Problem 1 */}
            <div className="p-8 bg-white rounded-lg shadow-xl">
              <h3 className="text-2xl font-semibold mb-4">Scalability</h3>
              <p className="text-gray-700">
                Generate 100s of Investment memo each day
              </p>
            </div>

            {/* Problem 2 */}
            <div className="p-8 bg-white rounded-lg shadow-xl">
              <h3 className="text-2xl font-semibold mb-4">Accuracy</h3>
              <p className="text-gray-700">
                Analysis of real-time data from every source available on Google.
              </p>
            </div>

            {/* Problem 3 */}
            <div className="p-8 bg-white rounded-lg shadow-xl">
              <h3 className="text-2xl font-semibold mb-4">Speed</h3>
              <p className="text-gray-700">
                Investment memo in just 10 seconds
              </p>
            </div>

            {/* Problem 4 */}
            <div className="p-8 bg-white rounded-lg shadow-xl">
              <h3 className="text-2xl font-semibold mb-4">Easy</h3>
              <p className="text-gray-700">
                Just 2 steps!
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section 4: Join the Revolution */}
      <section className="bg-blue-500 text-white py-16"  >
      
        <div className="container mx-auto text-center">
        
          <h2 className="text-3xl font-semibold mb-8">
            Your Path to Investment Success Begins Here
          </h2>
          {isLoggedIn ? (
            <Link
              to="/form"
              className="bg-yellow-500 hover:bg-yellow-400 text-blue-900 font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 transform hover:scale-105 ease-in-out"
            >
              Generate the Memo Now!
            </Link>
          ) : (
            <Link
              to="/signup"
              className="bg-yellow-500 hover:bg-yellow-400 text-blue-900 font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 transform hover:scale-105 ease-in-out"
            >
              Generate the Memo Now!
            </Link>
          )}
        </div>
      </section>

      {/* Section 6: Stay Informed */}
      {/* <section className="bg-gray-100 py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-semibold mb-8">Stay In the Know</h2>
          <p className="text-xl text-gray-700 mb-8">
            Subscribe to our newsletter for the latest insights, industry
            trends, and updates on venture capital.
          </p>
          <a
            href="https://forms.gle/fP7gcntWpk7njE5JA"
            className="bg-blue-500 hover:bg-blue-400 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 transform hover:scale-105 ease-in-out"
          >
            Subscribe Now
          </a>
        </div>
      </section> */}

      {/* Section 7: Contact Us */}
      <section className="py-12">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-semibold mb-8">Contact Us</h2>
          <p className="text-xl text-gray-700 mb-8">
            Got questions or need assistance? Contact our team anytime.
          </p>
          <a
            href="mailto:pradnyashil1999@gmail.com"
            className="bg-blue-500 hover:bg-blue-400
             text-white font-semibold py-3 px-8
              rounded-full shadow-lg transition
               duration-300 transform hover:scale-105 ease-in-out"
          >
            Contact Now
          </a>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
