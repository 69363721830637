import React from 'react'

const Aboutus = () => {
  return (
    <div style={{padding: "10px", margin: "10px 10px 10px 10px"}}>
      <h1 style={{fontWeight: "bold", padding: "10px"}}>About Us</h1>
<p style={{padding: "10px"}}>xInvestor generates Investment note on a startup using pitch deck and GenAI.</p>
<p style={{padding: "10px"}}>If you have any query regrading Site, Advertisement and any other issue, please feel free to contact at <strong>pradnyashil1999@gmail.com</strong></p>

    </div>
  )
}

export default Aboutus
