import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import arrow from "../../assets/arrow.png";
import { PuffLoader } from "react-spinners";
import { MdDeleteOutline } from "react-icons/md";
import { MdDelete } from "react-icons/md";

// const API_URL = process.env.REACT_APP_URL;

const API = "https://xinvestor-co.onrender.com";
// const API = "http://localhost:8080";

function StartupList() {
  const [startups, setStartups] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStartupNames = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await axios.get(`${API}/startups`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setStartups(response.data.startups);
      } catch (error) {
        setError(
          error.message || "An error occurred while fetching startup names."
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchStartupNames();
  }, []);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <PuffLoader color="#1a202c" loading size={99} speedMultiplier={2} />
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  if (startups.length === 0) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div>
          <h1 className="text-4xl font-semibold text-center">
            No Generated Investment Memos
          </h1>
          <div className="text-lg mt-4 text-center">
            <button
              className="text-blue-500 hover:underline text-xl uppercase"
              onClick={() => navigate("/form")}
            >
              Generate a new one
            </button>
          </div>
        </div>
      </div>
    );
  }

  //   const handleStartupClick =  (startupId) => {
  //     navigate(`/startup/${startupId}`);
  //   };

  const handleStartupDelete = async (startupId) => {
    try {
      const token = localStorage.getItem("token");

      // console.log("Deleting startup with ID:", startupId);
      // console.log(API_URL);

      // Send a DELETE request to delete the startup
      await axios.delete(`${API}/startups/id/${startupId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Remove the deleted startup from the local state
      setStartups((prevStartups) =>
        prevStartups.filter((startup) => startup._id !== startupId)
      );

      window.alert(`Startup deleted successfully!`);

    } catch (error) {
      setError(
        error.message || "An error occurred while deleting the startup."
      );
    }
  };

  return (
    <div className="w-full flex flex-col items-center my-8 h-full">
      <h1 className="flex items-center justify-center text-2xl md:text-4xl font-semibold m-6">
        History of Generated Investment Memos
      </h1>
      {startups.map((startup) => (
        <ul
          key={startup._id}
          className="mt-10 w-2/3 px-2 gap-4  border border-gray-900 hover:shadow-md rounded-md"
        >
          <li
            key={startup._id}
            className="cursor-pointer bg-white p-4 flex items-center justify-between w-full flex-grow"
          >
            <Link
              to={`/startup/${startup._id}`}
              className="text-gray-900 hover:underline text-xl flex-grow mr-1"
            >
              {startup.startupName}
            </Link>

            <MdDelete
              className="w-10 justify-self-end"
              alt="delete startup"
              onClick={() => handleStartupDelete(startup._id)}
            />

            <Link to={`/startup/${startup._id}`}>
              <img className="w-7 justify-self-end" src={arrow} alt="" />
            </Link>
          </li>
        </ul>
      ))}
    </div>
  );
}

export default StartupList;
