import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

// const API_URL = process.env.REACT_APP_URL;
const API = "https://xinvestor-co.onrender.com";
// const API = "http://localhost:8080";

const Signup = () => {
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        website: "",
        firmName: "",
        position: "",
    });
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = `${API}/api/users`;
            const { data: res } = await axios.post(url, data);
            navigate("/login");
            console.log(res.message);
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                setError(error.response.data.message);
            }
        }
    };

    return (
        <div className="w-full min-h-screen bg-[#f5f5f5] flex items-center justify-center">
            <div className="md:w-4/5 w-11/12 h-full md:h-[700px] md:flex overflow-hidden mb-5">
                <div className="md:h-full w-11/12 h-40 flex flex-1 flex-col items-center justify-center mx-auto bg-gray-900 rounded-lg md:rounded-l-lg mt-10">
                    <h1 className="md:mt-0 mb-5 text-white text-4xl self-center">
                        Welcome Back
                    </h1>
                    <Link to="/login">
                        <button
                            type="button"
                            className="border-none outline-none py-3 px-0 bg-white rounded-2xl w-44 font-bold text-base cursor-pointer uppercase"
                        >
                            Sign in
                        </button>
                    </Link>
                </div>
                <div
                    className="w-11/12 h-full mt-10 flex flex-2 flex-col items-center mx-auto justify-center bg-white rounded-lg md:rounded-r-lg md:w-1/2 mb-8"
                    style={{ overflowY: "hidden" }}
                >
                    <form
                        className="flex flex-col items-center w-full"
                        onSubmit={handleSubmit}
                    >
                        <h1 className="text-4xl md:mt-0 mt-4 mb-6 md:mb-2">
                            Create Account
                        </h1>
                        <input
                            type="text"
                            placeholder="First Name"
                            name="firstName"
                            onChange={handleChange}
                            value={data.firstName}
                            required
                            className="outline-none border-none w-11/12 md:w-96 p-4 rounded-xl bg-[#edf5f3] my-2 text-base"
                        />
                        <input
                            type="text"
                            placeholder="Last Name"
                            name="lastName"
                            onChange={handleChange}
                            value={data.lastName}
                            required
                            className="outline-none border-none  w-11/12 md:w-96 p-4 rounded-xl bg-[#edf5f3] my-2 text-base"
                        />
                        <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            onChange={handleChange}
                            value={data.email}
                            required
                            className="outline-none border-none  w-11/12 md:w-96 p-4 rounded-xl bg-[#edf5f3] my-2 text-base"
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            onChange={handleChange}
                            value={data.password}
                            required
                            className="outline-none border-none  w-11/12 md:w-96 p-4 rounded-xl bg-[#edf5f3] my-2 text-base"
                        />
                        <input
                            type="text"
                            placeholder="Website"
                            name="website"
                            onChange={handleChange}
                            value={data.website}
                            required
                            className="outline-none border-none  w-11/12 md:w-96 p-4 rounded-xl bg-[#edf5f3] my-2 text-base"
                        />
                        <input
                            type="text"
                            placeholder="Firm Name"
                            name="firmName"
                            onChange={handleChange}
                            value={data.firmName}
                            required
                            className="outline-none border-none  w-11/12 md:w-96 p-4 rounded-xl bg-[#edf5f3] my-2 text-base"
                        />
                        <input
                            type="text"
                            placeholder="Position"
                            name="position"
                            onChange={handleChange}
                            value={data.position}
                            required
                            className="outline-none border-none w-11/12 md:w-96 p-4 rounded-xl bg-[#edf5f3] my-2 text-base"
                        />
                        {error && (
                            <div className="w-3/4 p-4 mx-0 my-2 text-base bg-red-500 text-white rounded-lg text-center">
                                {error}
                            </div>
                        )}
                        <button
                            type="submit"
                            className="mb-10 border-none outline-none py-3 px-0 rounded-2xl w-44 font-bold text-base cursor-pointer uppercase bg-gray-900 text-white m-2"
                        >
                            Sign Up
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Signup;
