import React, { useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Hourglass } from "react-loader-spinner";

const API = "https://xinvestor-co.onrender.com";
// const API = "http://localhost:8080";

function DragDropFile() {
    const [step, setStep] = useState(1);
    const [dragActive, setDragActive] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [startupName, setStartupName] = useState("");
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);
    const navigate = useNavigate();

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(e.type === "dragenter" || e.type === "dragover");
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files.length > 0) {
            handleFiles(e.dataTransfer.files);
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.files.length > 0) {
            handleFiles([e.target.files[0]]);
        }
    };

    const handleFiles = (files) => {
        const file = files[0];
        if (file.type === "application/pdf") {
            setSelectedFile(file);
        } else {
            console.error("Invalid file type. Please select a PDF file.");
        }
    };

    const removeFile = () => {
        setSelectedFile(null);
    };

    const onButtonClick = () => {
        inputRef.current.click();
    };

    const handleStartupNameChange = (e) => {
        setStartupName(e.target.value);
    };

    const onNextClick = () => {
        // Validate startup name (you can add more validation logic)
        if (startupName.trim() !== "") {
            setStep(2);
        } else {
            alert("Please enter a valid startup name.");
        }
    };

    // const onUploadClick = async () => {
    //     if (!selectedFile) {
    //         console.error("Please select a PDF file.");
    //         return;
    //     }

    //     setLoading(true);

    //     const formData = new FormData();
    //     formData.append("file", selectedFile);
    //     formData.append("startupName", startupName);

    //     try {
    //         // Retrieve the token from local storage
    //         const token = localStorage.getItem("token");
    //         console.log(process.env.REACT_APP_URL);

    //         // Upload the PDF file to your server with the authorization header
    //         // const uploadResponse = await axios.post(
    //         //   "https://ddbot-n2ft.onrender.com/upload",
    //         //   // `https://ddbot-n2ft.onrender.com/upload}`,
    //         //   formData,
    //         //   {
    //         //     headers: {
    //         //       Authorization: `Bearer ${token}`,
    //         //     },
    //         //   }
    //         // );

    //         const uploadResponse = await axios.post(
    //             `https://ddbot-n2ft.onrender.com/upload`,
    //             formData,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             }
    //         );

    //         console.log("Upload successful", uploadResponse.data);

    //         // Reset form state after successful upload
    //         setUploadSuccess(true);
    //     } catch (error) {
    //         console.error("Error uploading file", error);
    //         // Handle errors
    //     } finally {
    //         setLoading(false);
    //         // Optional: Reset form state after upload
    //         setSelectedFile(null);
    //         setStartupName("");
    //         // // Reset upload success state to display the form again
    //         // setUploadSuccess(false);
    //     }
    // };
    const onUploadClick = async () => {
        if (!selectedFile) {
            console.error("Please select a PDF file.");
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("startupName", startupName);

        try {
            // Retrieve the token from local storage
            const token = localStorage.getItem("token");
            console.log("Token:", token);

            // Log the API request details before sending
            console.log("API Request Details:", {
                url: `${API}/upload`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: formData,
            });

            // Actual Axios request
            const uploadResponse = await axios.post(
                `${API}/upload`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log("Upload successful", uploadResponse.data);

            // Reset form state after successful upload
            setUploadSuccess(true);
        } catch (error) {
            console.error("Error uploading file", error);
            // Handle errors
        } finally {
            setLoading(false);
            // Optional: Reset form state after upload
            setSelectedFile(null);
            setStartupName("");
            // Reset upload success state to display the form again
            // setUploadSuccess(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center h-[110vh] bg-gray-100">
            {loading ? (
                <div className="text-center">
                    <Hourglass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="hourglass-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        colors={["#306cce", "#72a1ed"]}
                    />
                </div>
            ) : (
                <>
                    {step === 1 && (
                        <form className="flex flex-col justify-center items-center max-w-full h-full w-full text-center bg-gray-100 rounded-lg transition-opacity">
                            <h1 className="text-2xl md:text-4xl font-semibold mb-10 -mt-32">
                                Enter Startup Name:{" "}
                            </h1>
                            <input
                                type="text"
                                value={startupName}
                                onChange={handleStartupNameChange}
                                placeholder="Enter Startup Name"
                                className="mb-10 px-5 py-2 border rounded-lg w-60 md:w-64 h-12 md:h-14 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                            <button
                                type="button"
                                className="text-white md:text-base text-xs uppercase bg-gray-900 w-20 md:w-28 h-8 md:h-10 px-4 py-2 rounded-xl hover:bg-gray-600"
                                onClick={onNextClick}
                            >
                                Next
                            </button>
                        </form>
                    )}

                    {step === 2 && !uploadSuccess && (
                        <form
                            id="form-file-upload"
                            className={`max-w-full h-screen w-full p-4 text-center bg-white border rounded-lg shadow-md transition-opacity ${
                                uploadSuccess ? "invisible" : "visible"
                            } 
                            `}
                            onDragEnter={handleDrag}
                            onSubmit={(e) => e.preventDefault()}
                        >
                            <h1 className="text-3xl font-semibold mb-10 mt-10">
                                Upload File Here
                            </h1>
                            <input
                                type="file"
                                ref={inputRef}
                                className="hidden"
                                id="input-file-upload"
                                accept=".pdf"
                                onChange={handleChange}
                            />
                            <label
                                id="label-file-upload"
                                htmlFor="input-file-upload"
                                className={`flex h-5/6 items-center justify-center border-dashed border-2 rounded-lg p-8 ${
                                    dragActive
                                        ? "border-blue-500"
                                        : "border-gray-300"
                                }
                               
                                `}
                            >
                                <div>
                                    <p
                                        className={`mb-2 text-gray-500 ${
                                            selectedFile
                                                ? "opacity-0"
                                                : "opacity-100"
                                        }`}
                                    >
                                        Drag and drop your PDF file here or
                                    </p>
                                    <button
                                        className={`text-blue-500 hover:underline ${
                                            selectedFile
                                                ? "opacity-0"
                                                : "opacity-100"
                                        }`}
                                        onClick={onButtonClick}
                                    >
                                        Select PDF
                                    </button>
                                </div>
                            </label>
                            {selectedFile && (
                                <div className="-mt-96">
                                    <p className="mb-2 text-xl font-semibold mt-10">
                                        Selected File:
                                    </p>
                                    <ul>
                                        <li className="text-lg font-semibold px-2">
                                            {selectedFile.name}
                                        </li>
                                    </ul>
                                    <div className="my-2">
                                        <button
                                            className="text-gray-900 hover:underline text-base mr-4"
                                            onClick={removeFile}
                                        >
                                            Remove
                                        </button>
                                        <button
                                            className="text-white bg-gray-900 px-4 py-2 ml-4 rounded hover:bg-gray-600"
                                            onClick={onUploadClick}
                                        >
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            )}
                            {dragActive && (
                                <div
                                    id="drag-file-element"
                                    className="absolute inset-0 rounded-lg bg-blue-100"
                                    onDragEnter={handleDrag}
                                    onDragLeave={handleDrag}
                                    onDragOver={handleDrag}
                                    onDrop={handleDrop}
                                ></div>
                            )}
                        </form>
                    )}
                    {uploadSuccess && (
                        <div className="flex items-center justify-center">
                            <h1 className="md:text-3xl text-lg">
                                The File is Uploaded Successfully.
                            </h1>
                        </div>
                    )}
                    {/* {uploadSuccess && 
                    <div className="h-screen flex items-center justify-center">
                <div>
                    <h1 className="text-2xl md:text-4xl font-semibold text-center px-2">
                        Generated Investment Memo
                    </h1>
                    <div className="text-lg mt-4 text-center">
                        <button
                            className="text-blue-500 hover:underline text-base md:text-xl"
                            onClick={() => navigate("/home")}
                        >
                            Check Inves
                        </button>
                    </div>
                </div>
            </div>
            } */}
                </>
            )}
        </div>
    );
}

export default DragDropFile;
