import {
    FaLinkedinIn,
    FaInstagram,
    FaTwitter,
    FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <footer className="flex justify-center flex-col text-center text-lg py-6 bg-gray-900 text-white">
            <div className="flex flex-col md:justify-between md:flex-row items-center py-8 md:px-32">
                <div className="flex flex-col md:items-start">
                    <b className="mb-3 text-lg">About</b>
                    <Link to="/aboutus">
                        About Us
                    </Link>
                    <Link
                        to="/terms"
                        className="my-1"
                    >
                        Terms and Conditions
                    </Link>
                    <Link to="/privacy">Privacy</Link>
                    {/* <Link to="/refund">
                        Refund Policy
                    </Link> */}
                    {/* <Link href="https://web3hack.tech/">Promotions and events</Link> */}
                </div>

                <div className="flex flex-col my-8 md:my-0 md:items-start">
                    <b className="mb-3 text-lg">Support</b>
                    <a href="mailto:pradnyashil1999@gmail.com">Contact</a>
                    {/* <a href="/dcma">DCMA</a> */}
                </div>

                <div>
                    <b className="mt-4">Connect with us:</b>
                    <div className="flex justify-center my-2">
                        <a
                            href="https://www.linkedin.com/company/xinvestorco/"
                            target="#"
                            className="border-2 border-gray-200 rounded-full p-3 mx-1 hover:bg-white hover:text-black"
                        >
                            <FaLinkedinIn />
                        </a>
                        {/* <a
                            href="https://www.instagram.com/luster.network/"
                            target="#"
                            className="border-2 border-gray-200 rounded-full p-3 mx-1 hover:bg-white hover:text-black"
                        >
                            <FaInstagram />
                        </a> */}
                        <a
                            href="https://twitter.com/xinvestor"
                            target="#"
                            className="border-2 border-gray-200 rounded-full p-3 mx-1 hover:bg-white hover:text-black"
                        >
                            <FaTwitter />
                        </a>
                    </div>
                </div>
            </div>
            <p className="my-4 md:my-0">© xInvestor all rights reserved</p>
        </footer>
    );
};

export default Footer;
