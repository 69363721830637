import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import DragDropFile from "./components/FileUpload";
import Signup from "./components/Singup";
import Login from "./components/Login";
import StartupList from "./components/Historyy";
import FileDetails from "./components/FileDetails";
import Main from "./components/Main";
import Navbar from "./Layout/Navbar";
import Footer from "./Layout/Footer";
import LandingPage from "./components/LandingPage";
import Pricing from "./components/Pricing";
import Privacy from "./components/Privacy";
import Aboutus from "./components/Aboutus";
import Terms from "./components/Terms";
import Jobs from "./components/Jobs";

function App() {
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [user, setUser] = useState(false); // Derived state


    useEffect(() => {
        setToken(localStorage.getItem("token"));
        setLoading(false);
        setUser(token ? true : false);
        console.log("token", token);
      }, [token]);


    // handleLogin();

    // Render a loading indicator or any other content while checking authentication
    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <Navbar />
            <Routes>
                {!user && <Route path="/" element={<LandingPage />} />}
                {user && <Route path="/" element={<LandingPage />} />}
                {!user && <Route path="/pricing" element={<Pricing />} />}
                {user && <Route path="/pricing" element={<Pricing />} />}
                {!user && <Route path="/jobs" element={<Jobs />} />}
                {user && <Route path="/jobs" element={<Jobs />} />}
                {/* <Route path="/" element={<Navigate to="/login" />} /> */}
                {user && <Route path="/login" element={<Navigate to="/form" />}  />}
                <Route path="/login" element={<Login />} />
                {!user && <Route path="/signup" element={<Signup />} />}
                {user && <Route path="/form" element={<DragDropFile />} />}
                {!user && <Route path="/form" element={<Signup />} />}
                {/* {!user && <Route path="/home" element={<Navigate to="/login" replace  />} />} */}
                {user && <Route path="/startups" element={<StartupList />} />}
                {user && <Route path="/startup/:id" element={<FileDetails />} />}
                {user && <Route path="/main" element={<Main />} />}
                {/* <Route path="*" element={<Navigate to="/login" />} /> */}
                <Route path="*" element={<LandingPage />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/aboutus" element={<Aboutus />} />
                <Route path="/terms" element={<Terms />} />
            </Routes>
            <Footer />
        </>
    );
}

export default App;
